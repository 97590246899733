import React from "react"
import { Link, GatsbyLinkProps, useStaticQuery, graphql } from "gatsby"
//@ts-ignore
import Logo from "../../images/logo-tooth.png"
import { Navigation } from "../../types/Navigation"

const SidebarLink: React.FC<GatsbyLinkProps<string>> = ({ children, to }) => {
  return (
    <Link
      className="text-white my-1 hover:bg-primary-800 px-4 py-2 lg:py-1 rounded-md font-bold text-lg"
      to={to}
      activeClassName="text-yellow-200"
      partiallyActive={to !== "/"}
    >
      {children}
    </Link>
  )
}

const SideBar = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          navigation {
            name
            path
          }
        }
      }
    }
  `)

  const links: Array<Navigation> = data.site.siteMetadata.navigation

  return (
    <div className="bg-primary-500 h-full lg:px-0 overflow-y-auto w-64 lg:w-auto">
      <Link to="/">
        <img src={Logo} className="h-32 w-40 mx-auto py-6 " />
      </Link>
      <div className="px-2 lg:px-4 flex flex-col text-center lg:text-left">
        {links.map((item, i) => (
          <SidebarLink to={item.path} key={i}>
            {item.name}
          </SidebarLink>
        ))}
      </div>
    </div>
  )
}

export default SideBar
