import { Link, GatsbyLinkProps, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { Navigation } from "../types/Navigation"
// @ts-ignore
import Img from "../images/logo-tooth.png"

const HeaderLink: React.FC<GatsbyLinkProps<string>> = ({ children, to }) => {
  return (
    <Link
      className="text-lg block mt-4 lg:inline-block lg:mt-0 hover:text-yellow-200 lg:mx-5 text-white font-semibold pb-1"
      to={to}
      activeClassName="text-yellow-200 hover:text-yellow-300 font-bold"
    >
      {children}
    </Link>
  )
}

type Props = {
  setClosed(param: any): void
}
const ResponsiveMenuButton: React.FC<Props> = ({ setClosed }) => {
  function handleClick() {
    setClosed((prev: boolean) => !prev)
  }
  return (
    <div className="block lg:hidden">
      <button
        onClick={handleClick}
        className="flex items-center px-3 py-2 border rounded text-white hover:text-yello-200 hover:border-yellow-200 outline-none"
      >
        <svg className="fill-current h-5 w-5" viewBox="0 0 20 20">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
  )
}

const Header = () => {
  const [closed, setClosed] = useState(true)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          navigation {
            name
            path
          }
        }
      }
    }
  `)

  const links: Array<Navigation> = data.site.siteMetadata.navigation

  return (
    <nav className="flex items-center justify-between flex-wrap bg-primary-500 px-6 lg:px-16 py-5 lg:py-3">
      <div className="flex items-center text-white align-top">
        <Link to="/">
          <img src={Img} className="h-12 w-24" />
        </Link>
      </div>
      <ResponsiveMenuButton setClosed={setClosed} />
      <div
        className={`transition-height w-full lg:flex lg:items-center lg:w-auto align-bottom lg:h-full ${
          closed ? "hidden" : ""
        }`}
      >
        <div className="lg:flex justify-around">
          {links.map((link, i) => (
            <HeaderLink key={i} to={link.path}>
              {link.name}
            </HeaderLink>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Header
