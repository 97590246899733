/**
 * This Sidebar is shown only on small screens. It has a slide in/out animation
 */
import React from "react"
import Sidebar from "./index"

type Props = {
  handleClick(): void
  open: boolean
}
const AnimatedResponsiveSidenav: React.FC<Props> = ({ handleClick, open }) => {
  const isHidden = open ? "" : "hidden"
  const isOpen = open ? "translate-x-0" : "-translate-x-64"
  return (
    <>
      <div
        onClick={handleClick}
        className={`bg-gray-800 absolute h-full w-full z-10 opacity-50 lg:hidden ${isHidden}`}
      ></div>
      <div
        className={`z-20 h-full fixed lg:hidden transition-transform duration-300 ease-in transform ${isOpen}`}
      >
        <Sidebar className="h-full" />
      </div>
    </>
  )
}

export default AnimatedResponsiveSidenav
