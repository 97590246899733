import React from "react"
import Sidebar from "./Sidebar"

type Props = {
  className?: string
}
const Sidenav: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Sidebar />
    </div>
  )
}

export default Sidenav
