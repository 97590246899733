import React from "react"
import BaseLayout from "./BaseLayout"
import SideLayout from "./SideLayout"

type Props = {
  children: React.ReactNode
  pageContext: {
    layout: string
  }
}

const Template: React.FC<Props> = ({ children, pageContext }) => {
  if (pageContext && pageContext.layout === "base") {
    return <BaseLayout>{children}</BaseLayout>
  } else {
    return <SideLayout>{children}</SideLayout>
  }
}

export default Template
