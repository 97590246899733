import React from "react"

const Footer = () => {
  return (
    <footer className="bg-primary-600 px-4 pt-8 pb-4 text-white text-sm">
      <p>© Copyright {new Date().getFullYear()}</p>
    </footer>
  )
}

export default Footer
