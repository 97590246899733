import React, { useState } from "react"
import Sidenav from "../../components/Sidenav"
import Footer from "../../components/Footer"
import SimpleNavbar from "../../components/SimpleNavbar/SimpleNavbar"
import AnimatedResponsiveNavbar from "../../components/Sidenav/AnimatedResponsiveSidebar"

type Props = {
  children: React.ReactNode
}
const Layout: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false)

  function changeResponsiveSidenavState() {
    setOpen(prev => !prev)
  }

  return (
    <>
      <div className="relative bg-gray-200 min-h-screen flex flex-col">
        <AnimatedResponsiveNavbar
          open={open}
          handleClick={changeResponsiveSidenavState}
        />
        <SimpleNavbar handleClick={changeResponsiveSidenavState} />
        <div className="flex-grow flex">
          <Sidenav className="hidden lg:block w-3/12 bg-primary-500 fixed h-full" />
          <div className="w-full lg:w-9/12 px-8 lg:push-1/4 flex flex-col flex-1">
            <div className="flex-1 pt-8">{children}</div>
            <div className="-mx-8 flex-shrink mt-4">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
