/**
 * Top Navbar with only the logo and a menu button.
 */
import React from "react"
//@ts-ignore
import Logo from "../../images/logo-tooth.png"

type Props = {
  handleClick(): void
}
const SimpleNavbar: React.FC<Props> = ({ handleClick }) => {
  return (
    <div className="absolute h-16 bg-primary-500 block lg:hidden px-3 relative flex justify-center transition">
      <img className="h-12 w-24 self-center" src={Logo} />
      <div className="block lg:hidden flex align-middle h-full absolute left-0 top-0 mx-4">
        <button
          onClick={handleClick}
          className="flex items-center px-2 h-full text-white hover:text-yello-200 hover:border-yellow-200 outline-none"
        >
          <svg className="fill-current h-5 w-5" viewBox="0 0 20 20">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default SimpleNavbar
