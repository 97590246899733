/**
 * Common functionalities shared between gatsby-browser and gatsby-ssr
 */

const React = require("react")
const Layout = require("./src/templates/PageLayout/index").default

module.exports.generateLayout = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
