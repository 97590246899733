import React from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

type Props = {
  children: React.ReactNode
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1">
        <Header />
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default BaseLayout
