// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-layout-blog-layout-tsx": () => import("./../src/templates/BlogLayout/BlogLayout.tsx" /* webpackChunkName: "component---src-templates-blog-layout-blog-layout-tsx" */),
  "component---src-templates-category-layout-index-tsx": () => import("./../src/templates/CategoryLayout/index.tsx" /* webpackChunkName: "component---src-templates-category-layout-index-tsx" */)
}

